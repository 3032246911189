import React, {useEffect} from 'react';
import {getLanguage} from "../../util/lang";
import './TentangSaya.css';

function TentangSaya() {
    useEffect(() => {
        const script = document.createElement('script');
        script.defer = true;
        script.src = './assets/js/main/tentang_saya/tentang_saya.js';
        document.body.appendChild(script);   
        
        return () => {
            document.body.removeChild(script);
        };
    }, []);

    const lang = getLanguage();

    const copy = {
        header: "Tentang Mentor",
        about_1: `Saya lulus dari Universitas Pendidikan Indonesia (UPI) Bandung dengan gelar Sarjana 
                Ilmu Komputer pada tahun 2011. Sejak 2010, saya telah mengerjakan berbagai proyek 
                skala kecil hingga besar. Dengan pengalaman lebih dari 10 tahun, saya memiliki 
                keterampilan dalam mengatasi tantangan teknis, berbagai bahasa pemrograman, algoritma, 
                dan teknologi terkini.`,
        about_2: `Keahlian saya mencakup kolaborasi tim, manajemen proyek, dan komunikasi efektif. 
                Saya siap berbagi pengetahuan dan pengalaman untuk membantu orang lain memulai 
                atau meningkatkan karir mereka dalam programming.`,
    }

    const copyEn = {
        header: "About the Mentor",
        about_1: `I graduated from the Indonesia University of Education (UPI) Bandung with a Bachelor's degree 
                in Computer Science in 2011. Since 2010, I have worked on various projects ranging from small to large scale. 
                With over 10 years of experience, I have developed skills in tackling technical challenges, 
                various programming languages, algorithms, and the latest technologies.`,
        about_2: `My skills include team collaboration, project management, and effective communication. 
                I am ready to share my knowledge and experience to help others start or advance their careers in programming.`,
    }

  return (
    <section id="tentang-saya" className="tentang-saya section">

        <div className="container section-title" data-aos="fade-up">
            <h2>{lang == "id" ? copy.header : copyEn.header}</h2>
        </div>

        <div className="container">
            <div className="row gy-4">
                <div className="col-lg-12" data-aos="fade-up" data-aos-delay="100">
                    <div className="tentang-saya-sub d-flex align-items-start">
                        <div className="pic"><img src="./assets/img/tentang-saya/husni.jpg" className="img-fluid" alt="" /></div>
                        <div className="sub-info">
                        <h4>Husni Firmansyah</h4>
                        <span>Ex Tokopedia | Ex Telkom Indonesia</span>
                        <p>
                            {lang == "id" ? copy.about_1 : copyEn.about_1}
                        </p>
                        <p>
                            {lang == "id" ? copy.about_2 : copyEn.about_2}
                        </p>
                        <div className="social">
                            <a href="https://husnifirmansyah.online" target='_blank'><i className="bi bi-globe-americas"></i></a>
                            <a href="https://www.youtube.com/@husni.firmansyah" target='_blank'><i className="bi bi-youtube"></i></a>
                            <a href="https://www.linkedin.com/in/husni-firmansyah-a7930658/" target='_blank'> <i className="bi bi-linkedin"></i> </a>
                            <a href="mailto:husni.firmansyah@kelaskoding.id?subject=Konsultasi KELASKODING.ID" target='_blank'><i className="bi bi-envelope"></i></a>
                        </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>

    </section>
  );
}

export default TentangSaya;
