import React from 'react';
import {getLanguage} from "../../../util/lang";

function Modal4() {
    const lang = getLanguage();

    const copy = {
        header: "Lokasi Kelas",
        item: "Kelas ini akan dilaksanakan secara online menggunakan Zoom.",
        closeButton: "Tutup",
    }

    const copyEn = {
        header: "Class Location",
        item: "This class will be conducted online using Zoom.",
        closeButton: "Close",
    }
  return (
    <div className="modal modal-4">
        <article className="modal-container">
            <header className="modal-container-header">
                <h4 className="modal-container-title">
                    {lang == "id" ? copy.header : copyEn.header}
                </h4>
                
            </header>
            <section className="modal-container-body rtf">
                <p>
                    {lang == "id" ? copy.item : copyEn.item}
                </p>
            </section>
            <footer className="modal-container-footer">
                <button className="button is-primary button-close-4">{lang == "id" ? copy.closeButton : copyEn.closeButton}</button>
            </footer>
        </article>
    </div>
    
  );
}

export default Modal4;
