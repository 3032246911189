import React, { useEffect} from 'react';
import './FormDaftar.css';
import {getLanguage} from "../../util/lang";

function FormDaftar() {
    useEffect(() => {
        const script = document.createElement('script');
        script.defer = true;
        script.src = './assets/js/main/form_daftar/form_daftar.js';
        // script.async = true;
        document.body.appendChild(script);   
        
        return () => {
            document.body.removeChild(script);
        };
    }, []);

    const lang = getLanguage();

    const copy = {
        header: "Formulir Pendaftaran",
        info_1: "Tentang Mentor",
        info_2: "Video Koding",
        info_3: "LinkedIn",
        info_4: "Bertanya",
        label_1: "Nama",
        label_2: "Kota",
        label_3: "Saya akan melampirkan resi pembayaran.",
        error: "Input tidak boleh kosong.",
        button: "Daftar",
    }

    const copyEn = {
        header: "Registration Form",
        info_1: "About The Mentor",
        info_2: "Coding Video",
        info_3: "LinkedIn",
        info_4: "Ask Questions",
        label_1: "Name",
        label_2: "Country",
        label_3: "I have already paid the fee.",
        error: "Input cannot be empty.",
        button: "Register",
    }

  return (
    <section id="form-daftar" className="form-daftar section">
        <div className="container section-title" data-aos="fade-up">
            <h2>{lang == "id" ? copy.header : copyEn.header}</h2>
        </div>

        <div className="container" data-aos="fade-up" data-aos-delay="100">
            <div className="row gy-4">
                <div className="col-lg-5">
                    <div className="info-wrap">
                        <div className="info-item tentang-saya d-flex" data-aos="fade-up" data-aos-delay="300">
                            <i className="bi bi-globe-americas flex-shrink-0"></i>
                            <div>
                                <h3>{lang == "id" ? copy.info_1 : copyEn.info_1}</h3>
                                {/* <p>https://husnifirmansyah.online</p> */}
                            </div>
                        </div>

                        <div className="info-item youtube d-flex" data-aos="fade-up" data-aos-delay="300">
                            <i className="bi bi-youtube flex-shrink-0"></i>
                            <div>
                                <h3>{lang == "id" ? copy.info_2 : copyEn.info_2}</h3>
                                {/* <p>https://www.youtube.com/@husni.firmansyah</p> */}
                            </div>
                        </div>

                        <div className="info-item linkedin d-flex" data-aos="fade-up" data-aos-delay="300">
                            <i className="bi bi-linkedin flex-shrink-0"></i>
                            <div>
                                <h3>{lang == "id" ? copy.info_3 : copyEn.info_3}</h3>
                                {/* <p>https://www.linkedin.com/in/husni-firmansyah-a7930658/</p> */}
                            </div>
                        </div>

                        <div className="info-item mailto d-flex" data-aos="fade-up" data-aos-delay="400">
                            <i className="bi bi-envelope flex-shrink-0"></i>
                            <div>
                                <h3>{lang == "id" ? copy.info_4 : copyEn.info_4}</h3>
                                {/* <p>husni.firmansyah@kelaskoding.id</p> */}
                            </div>
                        </div>


                    </div>
                </div>

                <div className="col-lg-7">
                    <form action="forms/form-daftar.php" method="post" className="php-email-form" data-aos="fade-up" data-aos-delay="200">
                        <div className="row gy-4">

                        <div className='col-md-12'>
                            <h6>You will be registered for Batch 1 (Start Date: Oct 21, 2024). After you pay and register, your email will be registered as a participant in KELASKODING.ID.</h6>
                        </div>

                        <div className="col-md-12">
                            <label className="pb-2">{lang == "id" ? copy.label_1 : copyEn.label_1}</label>
                            <input type="text" name="name" id="name" className="form-control" required="" />
                        </div>

                        <div className="col-md-12">
                            <label className="pb-2">{lang == "id" ? copy.label_2 : copyEn.label_2}</label>
                            <input type="text" className="form-control" name="kota" id="kota" required="" />
                        </div>

                        {/* <div className="col-md-12">
                            <label className="pb-2">Kelas</label>
                            <select id='kelas' name='kelas' className="form-control">
                                <option value=''>Pilih Kelas</option>
                                <option value='Kelas Pagi'>Kelas Pagi</option>
                                <option value='Kelas Siang'>Kelas Siang</option>
                                <option value='Kelas Sore'>Kelas Sore</option>
                                <option value='Kelas Malam'>Kelas Malam</option>
                            </select>
                        </div> */}
                        {/* <div className="col-md-12">
                            <label className="pb-2">Daftar (WhatsApp / Gmail)</label>
                            <select id='daftar_via' name='daftar_via' className="form-control">
                                <option value=''>Pilih</option>
                                <option value='via_whatsapp'>Whatsapp</option>
                                <option value='via_gmail'>Gmail</option>
                            </select>
                        </div> */}
                        <div className="col-md-12">
                            <p className="pb-2">
                                <input type="checkbox" className="read-all" id="read-all" />&nbsp;&nbsp;
                                {lang == "id" ? copy.label_3 : copyEn.label_3}
                            </p>
                        </div>
                        <div className="col-md-12 text-center">
                            <div className="loading">Loading</div>
                            <div className="error-message">{lang == "id" ? copy.error : copyEn.error}</div>
                            <div className="sent-message">Your message has been sent. Thank you!</div>

                            <button type="submit" className='daftar' id="daftar" disabled>{lang == "id" ? copy.button : copyEn.button}</button>
                        </div>

                        </div>
                    </form>
                </div>

            </div>
        </div>

    </section>
  );
}

export default FormDaftar;
