import React, {useEffect} from 'react';
import './Beranda.css';
import { getLanguage } from '../../util/lang';

function Beranda() {
  useEffect(() => {
    const script = document.createElement('script');
    script.defer = true;
    script.src = './assets/js/main/beranda/beranda.js';
    document.body.appendChild(script);   
    
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const lang = getLanguage();

  const copy = {
    title: "Memecahkan masalah dengan koding",
    subtitle: "Ini bukan hanya tentang koding, melainkan tentang belajar bagaimana memecahkan masalah melalui koding. Ayo, daftar segera!",
    registerButton: "Daftar Sekarang",
    infoButton: "Informasi Kelas",
  };

  const copyEn = {
    title: "Solving problems with coding",
    subtitle: "This isn't just about coding. It's about learning how to solve problems through coding. Register now!",
    registerButton: "Register Now",
    infoButton: "Class Information",
  };

  // console.log("language", getLanguage());

  return (
    <section id="beranda" className="beranda section">
        <div className="container">
            <div className="row gy-4">
                <div className="col-lg-6 col-md-6 order-2 order-lg-1 d-flex flex-column justify-content-center" data-aos="zoom-out">
                  <h1>{lang == "id" ? copy.title : copyEn.title}</h1>
                  <p>{lang == "id" ? copy.subtitle : copyEn.subtitle}</p>
                  <div className="d-flex">
                      <a href="#" className="btn-register">{lang=="id" ? copy.registerButton : copyEn.registerButton}</a>
                      <a href="#" className="btn-course-info d-flex align-items-center"><i className="bi bi-info-square"></i><span>{lang=="id" ? copy.infoButton : copyEn.infoButton}</span></a>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 order-1 order-lg-2 beranda-img" data-aos="zoom-out" data-aos-delay="200">
                  <img src="./assets/img/beranda-img.png" className="img-fluid animated" alt="" />
                </div>
            </div>
        </div>
    </section>
  );
}

export default Beranda;
