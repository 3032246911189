import React from 'react';
import {getLanguage} from "../../../util/lang";

function Modal5() {
    const lang = getLanguage();

    const copy = {
        header: "Tanggal Mulai Kelas",
        item_1: `Kelas akan dimulai pada tanggal 16 September 2024 dan akan berlangsung selama 40 pertemuan, 
                dengan setiap pertemuan berlangsung selama 1 jam.`,
        item_2: `Kelas akan dilaksanakan pada hari kerja Senin hingga Jumat, kecuali pada hari libur nasional.`,
        item_3: `Undangan kalender akan dikirim melalui email pada tanggal 14 September 2024, bersama dengan 
                link Zoom.`,
        closeButton: "Tutup",
    }

    const copyEn = {
        header: "Class Start Date",
        item_1: `The class will start on September 16, 2024, and will run for 40 sessions, with each session lasting 1 hour.`,
        item_2: `The class will be held on weekdays, Monday through Friday, except on national holidays in Indonesia.`,
        item_3: `A calendar invitation will be sent via email on September 14, 2024, along with the Zoom link.`,
        closeButton: "Close",
    }
  return (
    <div className="modal modal-5">
        <article className="modal-container">
            <header className="modal-container-header">
                <h4 className="modal-container-title">
                    {lang == "id" ? copy.header : copyEn.header}
                </h4>
                
            </header>
            <section className="modal-container-body rtf">
                <p>
                    <i className='bi bi-1-square-fill'></i>&nbsp;
                    {lang == "id" ? copy.item_1 : copyEn.item_1} <br />

                    <i className='bi bi-2-square-fill'></i>&nbsp;
                    {lang == "id" ? copy.item_2 : copyEn.item_2} <br />

                    <i className='bi bi-3-square-fill'></i>&nbsp;
                    {lang == "id" ? copy.item_3 : copyEn.item_3}  <br />

                </p>
                
            </section>
            <footer className="modal-container-footer">
                <button className="button is-primary button-close-5">{lang == "id" ? copy.closeButton : copyEn.closeButton}</button>
            </footer>
        </article>
    </div>
    
  );
}

export default Modal5;
