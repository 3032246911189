import React from 'react';
import {getLanguage} from "../../../util/lang";

function Modal3() {
    const lang = getLanguage();

    const copy = {
        header: "Proses Pembelajaran",
        item_1: "Anda akan mempelajari dasar-dasar bahasa pemrograman Golang.",
        item_2: "Anda akan diajarkan cara memecahkan masalah pemrograman menggunakan Golang.",
        closeButton: "Tutup",
    }

    const copyEn = {
        header: "Learning Process",
        item_1: "You will learn the basics of the Go programming language.",
        item_2: "You will be taught how to solve programming problems using Go.",
        closeButton: "Close",
    }
  return (
    <div className="modal modal-3">
        <article className="modal-container">
            <header className="modal-container-header">
                <h4 className="modal-container-title">
                    {lang == "id" ? copy.header : copyEn.header}
                </h4>
                
            </header>
            <section className="modal-container-body rtf">
                {/* <h6>Materi Utama</h6> */}
                <p>
                    <i className='bi bi-1-square-fill'></i>&nbsp;
                    {lang == "id" ? copy.item_1 : copyEn.item_1} <br />

                    <i className='bi bi-2-square-fill'></i>&nbsp;
                    {lang == "id" ? copy.item_2 : copyEn.item_2} <br />

                </p>
                {/* <h6>Materi Bonus</h6>
                <p>
                    <i className='bi bi-1-square-fill'></i>&nbsp;
                    Anda akan diajarkan cara membuat klien API menggunakan Golang dan mengintegrasikannya 
                    dengan antarmuka blog yang disediakan oleh KELASKODING.ID. <br />

                    <i className='bi bi-2-square-fill'></i>&nbsp;
                    Anda akan diajarkan cara membuat koneksi ke MongoDB, 
                    membuat tabel di MongoDB, serta melakukan query dan mutasi menggunakan Golang. <br />

                    <i className='bi bi-3-square-fill'></i>&nbsp;
                    Anda akan mempelajari cara mempublikasikan blog yang telah dibuat ke internet. <br />
                </p> */}
            </section>
            <footer className="modal-container-footer">
                <button className="button is-primary button-close-3">{lang == "id" ? copy.closeButton : copyEn.closeButton}</button>
            </footer>
        </article>
    </div>
    
  );
}

export default Modal3;
