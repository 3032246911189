import React, {useEffect} from 'react';
import './Header.css';
import Sitename from './sitename/Sitename.js';
import Navmenu from './navmenu/Navmenu.js';

function Header() {
  useEffect(() => {
    const script = document.createElement('script');
    script.defer = true;
    script.src = './assets/js/header/header.js';
    document.body.appendChild(script);   
    
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <header id="header" className="header d-flex align-items-center fixed-top">
        <div className="container-fluid container-xl position-relative d-flex align-items-center">

            <Sitename />
            <Navmenu />

        </div>
    </header>
  );
}

export default Header;
