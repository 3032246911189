import React, {useEffect} from 'react';
import './Modal.css';
import Modal0 from './Modal0.js';
import Modal1 from './Modal1.js';
import Modal2 from './Modal2.js';
import Modal3 from './Modal3.js';
import Modal4 from './Modal4.js';
import Modal5 from './Modal5.js';
import Modal6 from './Modal6.js';
import Modal7 from './Modal7.js';
import Modal8 from './Modal8.js';
import Modal9 from './Modal9.js';

function Modal() {
    useEffect(() => {
        const script = document.createElement('script');
        script.defer = true;
        script.src = './assets/js/main/info_kursus/modal/modal.js';
        document.body.appendChild(script);   
        
        return () => {
            document.body.removeChild(script);
        };
    }, []);
  return (
    <>
        <Modal0 />
        <Modal1 />
        <Modal2 />
        <Modal3 />
        <Modal4 />
        <Modal5 />
        <Modal6 />
        <Modal7 />
        <Modal8 />
        <Modal9 />
    </>
    
  );
}

export default Modal;
