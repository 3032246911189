import React from 'react';
import {getLanguage} from "../../../util/lang";

function Modal0() {
    const arrKelas = [
        { kelas: 'Kelas Malam', waktu: '20.00 - 21.00', kuota: 'Masih Tersedia' },
    ];

    const arrKelasEn = [
        { batch: 'Batch 1', totalSession: '40 sessions', startDate: 'Oct 21, 2024', waktu: '17.00 - 18.00', kuota: 'Available' },
    ];

    const lang = getLanguage();

    const copy = {
        header: "Waktu Kelas",
    }

    const copyEn = {
        header: "Class Schedule",
    }

  return (
    <div className="modal modal-0">
        <article className="modal-container">
            <header className="modal-container-header">
                <h4 className="modal-container-title">
                    {lang == "id" ? copy.header : copyEn.header}
                </h4>
            </header>
            <section className="modal-container-body rtf">
                <div className='content-desktop'>
                    {
                        lang == "id" ? (
                            <>
                                <div className='title-table'>Versi Bahasa</div>
                                <table className="table table-striped">
                                    <thead>
                                        <tr>
                                        <th scope="col">Kelas</th>
                                        <th scope="col">Waktu</th>
                                        <th scope="col">Kuota</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {arrKelas.map((item, itemIndex) => (
                                            <tr key={itemIndex}>
                                                {/* <th scope="row">{itemIndex+1}</th> */}
                                                <td>{item.kelas}</td>
                                                <td>{item.waktu}</td>
                                                <td className='text-success'>{item.kuota}</td>
                                                {/* <td>{item.sudah_daftar}</td>
                                                <td>{item.sisa_kuota}</td> */}
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </>
                        ) : (
                            <>
                                <div className='title-table'>English Version</div>
                                <table className="table table-striped">
                                    <thead>
                                        <tr>
                                        <th scope="col">Batch</th>
                                        <th scope="col">Session Count (In Weekdays)</th>
                                        <th scope="col">Start Date</th>
                                        <th scope="col">Time (Jakarta Time, UTC+7)</th>
                                        <th scope="col">Quota</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {arrKelasEn.map((item, itemIndex) => (
                                            <tr key={itemIndex}>
                                                {/* <th scope="row">{itemIndex+1}</th> */}
                                                <td>{item.batch}</td>
                                                <td>{item.totalSession}</td>
                                                <td>{item.startDate}</td>
                                                <td>{item.waktu}</td>
                                                <td className='text-success'>{item.kuota}</td>
                                                {/* <td>{item.sudah_daftar}</td>
                                                <td>{item.sisa_kuota}</td> */}
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </>
                        )
                    }
                
                </div>

                <div className='content-mobile'>
                    {
                        lang == "id" ? (
                            <>
                                <div className='title-table'>Versi Bahasa</div>
                                {arrKelas.map((item, itemIndex) => (
                                    <div key={itemIndex}>
                                        <table className='table table-striped'>
                                            <tbody>
                                                <tr>
                                                    <th>Kelas</th>
                                                    <td>{item.kelas}</td>
                                                </tr>
                                                <tr>
                                                    <th>Waktu</th>
                                                    <td>{item.waktu}</td>
                                                </tr>
                                                <tr>
                                                    <th>Kuota</th>
                                                    <td className='text-success'>{item.kuota}</td>
                                                </tr>
                                                {/* <tr>
                                                    <th>Sudah Daftar</th>
                                                    <td>{item.sudah_daftar}</td>
                                                </tr>
                                                <tr>
                                                    <th>Sisa Kuota</th>
                                                    <td>{item.sisa_kuota}</td>
                                                </tr> */}
                                            </tbody>
                                        </table>
                                        { itemIndex < arrKelas.length-1 && <hr /> }
                                    </div>
                                ))}
                            </>
                        ) : (
                            <>
                                <div className='title-table'>English Version</div>
                                {arrKelasEn.map((item, itemIndex) => (
                                    <div key={itemIndex}>
                                        <table className='table table-striped'>
                                            <tbody>
                                                <tr>
                                                    <th>Batch</th>
                                                    <td>{item.batch}</td>
                                                </tr>
                                                <tr>
                                                    <th>Session Count (In Weekdays)</th>
                                                    <td>{item.totalSession}</td>
                                                </tr>
                                                <tr>
                                                    <th>Start Date</th>
                                                    <td>{item.startDate}</td>
                                                </tr>
                                                <tr>
                                                    <th>Time (Jakarta Time, UTC+7)</th>
                                                    <td>{item.waktu}</td>
                                                </tr>
                                                <tr>
                                                    <th>Quota</th>
                                                    <td className='text-success'>{item.kuota}</td>
                                                </tr>
                                                {/* <tr>
                                                    <th>Sudah Daftar</th>
                                                    <td>{item.sudah_daftar}</td>
                                                </tr>
                                                <tr>
                                                    <th>Sisa Kuota</th>
                                                    <td>{item.sisa_kuota}</td>
                                                </tr> */}
                                            </tbody>
                                        </table>
                                        { itemIndex < arrKelas.length-1 && <hr /> }
                                    </div>
                                ))}
                            </>
                        )
                    }
                    
                </div>
            </section>
            <footer className="modal-container-footer">
                <button className="button is-primary button-close-0">Tutup</button>
            </footer>
        </article>
    </div>
    
  );
}

export default Modal0;
