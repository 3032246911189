import React from 'react';
import {getLanguage} from "../../../util/lang";

function Modal7() {
    const lang = getLanguage();

    const copy = {
        header: "Benefit",
        item_1: `Anda akan mendapatkan materi dan soal latihan saat proses kelas berjalan.`,
        item_2: `Sertifikat digital akan diberikan setelah Anda menyelesaikan semua materi kelas.`,
        closeButton: 'Tutup',
    }

    const copyEn = {
        header: "Benefits",
        item_1: `You will receive materials and practice questions during the course.`,
        item_2: `A digital certificate will be awarded after you complete all the course materials.`,
        closeButton: 'Close',
    }
  return (
    <div className="modal modal-7">
        <article className="modal-container">
            <header className="modal-container-header">
                <h4 className="modal-container-title">
                    {lang == "id" ? copy.header : copyEn.header}
                </h4>
                
            </header>
            <section className="modal-container-body rtf">
                <p>
                    <i className='bi bi-1-square-fill'></i>&nbsp;
                    {/* Anda akan mendapatkan akses materi KELASKODING.ID, di mana Anda dapat mengakses materi pembelajaran, latihan, 
                    dan rekaman video pertemuan. Akses ini akan diberikan selama 90 hari sejak kelas dimulai. <br /> */}
                    {lang == "id" ? copy.item_1 : copyEn.item_1} <br />

                    {/* <i className='bi bi-2-square-fill'></i>&nbsp;
                    Anda akan mendapatkan keahlian pemrograman jika Anda mengikuti kelas ini dengan disiplin. <br /> */}

                    {/* <i className='bi bi-3-square-fill'></i>&nbsp;
                    Anda akan memiliki portofolio berupa blog setelah menyelesaikan kursus ini. <br /> */}

                    <i className='bi bi-2-square-fill'></i>&nbsp;
                    {lang == "id" ? copy.item_2 : copyEn.item_2} <br />
                </p>
            </section>
            <footer className="modal-container-footer">
                <button className="button is-primary button-close-7">{lang == "id" ? copy.closeButton : copyEn.closeButton}</button>
            </footer>
        </article>
    </div>
    
  );
}

export default Modal7;
