import React from 'react';
import './Main.css';
import Beranda from './beranda/Beranda.js';
import InfoKursus from './info_kursus/InfoKursus.js';
import TentangSaya from './tentang_saya/TentangSaya.js';
import Faq from './faq/Faq.js';
import FormDaftar from './form_daftar/FormDaftar.js';
import Legalitas from './legalitas/Legalitas.js';

function Main() {

  return (
    <main className="main">
        <Beranda />
        <Legalitas />
        <TentangSaya />
        <InfoKursus />
        <FormDaftar />
        {/* <Faq /> */}
    </main>
  );
}

export default Main;
