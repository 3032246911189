import React from 'react';

function Modal9() {
  return (
    <div className="modal modal-9">
        <article className="modal-container">
            <header className="modal-container-header">
                <h4 className="modal-container-title">
                    Daftar Siswa
                </h4>
                
            </header>
            <section className="modal-container-body rtf">
                <p>Kursus akan dimulai pada tanggal 29 Juli 2024</p>
                <table className="table table-striped">
                    <thead>
                        <tr>
                        <th scope="col">#</th>
                        <th scope="col">Nama</th>
                        <th scope="col">Kota</th>
                        <th scope="col">Kelas</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <th scope="row">1</th>
                            <td>Ganjar Sapaat</td>
                            <td>Bandung Barat</td>
                            <td>Kelas Malam</td>
                        </tr>
                        <tr>
                            <th scope="row">2</th>
                            <td>Rahma Aliyah</td>
                            <td>Cianjur</td>
                            <td>Kelas Malam</td>
                        </tr>
                    </tbody>
                </table>
            </section>
            <footer className="modal-container-footer">
                <button className="button is-primary button-close-9">Tutup</button>
            </footer>
        </article>
    </div>
    
  );
}

export default Modal9;
