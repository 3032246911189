import React from 'react';
import {getLanguage} from "../../../util/lang";

function Modal2() {
    const lang = getLanguage(); 

    const copy = {
        header: "Proses Pendaftaran",
        item_1: `Lakukan pembayaran melalui transfer ke rekening Mandiri 182.000.383.7788 atas nama Husni Firmansyah.`,
        item_2: `Isi formulir pendaftaran dan klik tombol Daftar.`,
        item_3: `Setelah mengklik tombol Daftar, Anda akan diarahkan ke aplikasi email Anda.`,
        item_4: `Lampirkan bukti/resi pembayaran pada email.`,
        item_5: `Kirim email tersebut.`,
        item_6: `Setelah Anda melakukan pendaftaran dan pembayaran, Anda akan menerima faktur 
                digital di email Anda paling lambat dalam 24 jam.`,
        closeButton: "Tutup",
    }

    const copyEn = {
        header: "Registration Process",
        item_1: `Make the payment via transfer to the PayPal account husni.firmansyah@gmail.com.`,
        item_2: `Fill out the registration form and click the Register button.`,
        item_3: `After clicking the Register button, you will be redirected to your email application.`,
        item_4: `Attach the payment receipt/proof to the email.`,
        item_5: `Send the email.`,
        item_6: `After you complete the registration and payment, you are already registered as a participant in KELASKODING.ID. `,
        closeButton: "Close",
    }
  return (
    <div className="modal modal-2">
        <article className="modal-container">
            <header className="modal-container-header">
                <h4 className="modal-container-title">
                    {lang == "id" ? copy.header : copyEn.header}
                </h4>
                
            </header>
            <section className="modal-container-body rtf">
                <p>
                    <i className='bi bi-1-square-fill'></i>&nbsp;
                    {lang == "id" ? copy.item_1 : copyEn.item_1} <br />

                    <i className='bi bi-2-square-fill'></i>&nbsp;
                    {lang == "id" ? copy.item_2 : copyEn.item_2} <br />

                    <i className='bi bi-3-square-fill'></i>&nbsp;
                    {lang == "id" ? copy.item_3 : copyEn.item_3} <br />

                    <i className='bi bi-4-square-fill'></i>&nbsp;
                    {lang == "id" ? copy.item_4 : copyEn.item_4} <br />

                    <i className='bi bi-5-square-fill'></i>&nbsp;
                    {lang == "id" ? copy.item_5 : copyEn.item_5} <br />

                    <i className='bi bi-6-square-fill'></i>&nbsp;
                    {lang == "id" ? copy.item_6 : copyEn.item_6} <br />
                </p>
            </section>
            <footer className="modal-container-footer">
                <button className="button is-primary button-close-2">{lang == "id" ? copy.closeButton : copyEn.closeButton}</button>
            </footer>
        </article>
    </div>
    
  );
}

export default Modal2;
