import React from 'react';
import './Legalitas.css';
import {getLanguage} from "../../util/lang";

function Legalitas() {
    const lang = getLanguage();

    const copy = {
        header: "Legalitas",
        merkDesc: `
        Saya dengan bangga mengumumkan bahwa merek KELASKODING.ID kini telah resmi terdaftar 
        di Direktorat Jenderal Kekayaan Intelektual (DJKI). Untuk memverifikasi pendaftaran ini, 
        Anda dapat mengunjungi
        `,
        djkiWeb: "website DJKI",
    };

    const copyEn = {
        header: "Legality",
        merkDesc: `
        I am proud to announce that the KELASKODING.ID brand is now officially registered with 
        the Directorate General of Intellectual Property (DGIP). 
        To verify this registration, you can visit
        `,
        djkiWeb: "DJKI website",
    };
    
  return (
    <section id="legalitas" className="legalitas section">

        <div className="container section-title" data-aos="fade-up">
            <h2>{lang=="id" ? copy.header : copyEn.header}</h2>
        </div>

        <div className="container">
            <div className="row gy-4">

                <div className="col-lg-12" data-aos="fade-up" data-aos-delay="100">
                    <div className="legalitas-sub d-flex align-items-start">
                        <div className="sub-info">
                            <p>
                                {lang=="id" ? copy.merkDesc : copyEn.merkDesc}
                                <a href='https://pdki-indonesia.dgip.go.id/search?type=trademark&page=1&keyword=kelaskoding.id' target='_blank'>{lang=="id" ? copy.djkiWeb : copyEn.djkiWeb}</a>.
                            </p>
                            {/* <p>
                                Dan saya dengan bangga mengumumkan bahwa perusahaan saya telah terdaftar secara resmi di OSS 
                                (Online Single Submission) melalui <a href='https://oss.go.id' target='_blank'>oss.go.id</a> dengan Nomor Induk Berusaha (NIB) <b>0307240013287</b>. 
                                Hal ini menegaskan komitmen saya dalam menjalankan bisnis sesuai dengan peraturan dan ketentuan 
                                yang berlaku di Indonesia.
                            </p>
                            <p>
                            Untuk memverifikasi NIB saya, silakan ikuti langkah-langkah berikut: <br />
                            <i className="bi bi-1-square-fill"></i>&nbsp;
                            Kunjungi situs web <a href='https://oss.go.id' target='_blank'>oss.go.id</a>. <br />

                            <i className="bi bi-2-square-fill"></i>&nbsp;
                            Pada bagian pencarian NIB, masukkan nomor <b>0307240013287</b>.
                            </p> */}
                        </div>
                    </div>
                </div>

            </div>
        </div>

    </section>
  );
}

export default Legalitas;
