import React from 'react';
import {getLanguage} from "../../../util/lang";

function Modal6() {
    const lang = getLanguage();

    const copy = {
        header: "Harga Kelas",
        item_1: "Harga kelas adalah Rp. 4.000.000.",
        item_2: `Pembayaran dapat dilakukan melalui transfer ke rekening MANDIRI 182.000.383.7788 atas nama Husni Firmansyah.`,
        closeButton: "Tutup",
    }

    const copyEn = {
        header: "Class Price",
        item_1: "The price of the class is USD 300.",
        item_2: `Payment can be made via transfer to the PayPal account husni.firmansyah@gmail.com.`,
        closeButton: "Close",
    }
  return (
    <div className="modal modal-6">
        <article className="modal-container">
            <header className="modal-container-header">
                <h4 className="modal-container-title">
                    {lang == "id" ? copy.header : copyEn.header}
                </h4>
                
            </header>
            <section className="modal-container-body rtf">
                <p>
                    <i className='bi bi-1-square-fill'></i>&nbsp;
                    {lang == "id" ? copy.item_1 : copyEn.item_1} <br />

                    <i className='bi bi-2-square-fill'></i>&nbsp;
                    {lang == "id" ? copy.item_2 : copyEn.item_2} <br />
                </p>
            </section>
            <footer className="modal-container-footer">
                <button className="button is-primary button-close-6">{lang == "id" ? copy.closeButton : copyEn.closeButton}</button>
            </footer>
        </article>
    </div>
    
  );
}

export default Modal6;
