import React from 'react';
import {getLanguage} from "../../../util/lang";

function Modal1() {
    const lang = getLanguage();

    const copy = {
        header: "Prasyarat Kelas",
        item_1: `Anda harus mempunyai komputer dengan setidaknya CPU core i3 (atau setara) dan RAM 4 GB untuk 
                memastikan kelancaran dalam proses belajar.`,
        item_2: `Anda harus menyediakan waktu 1 jam untuk setiap pertemuan dan waktu tambahan untuk latihan mandiri. 
                Soal latihan akan disediakan saat proses pembelajaran.`,  
        item_3: `Anda harus membayar biaya kelas untuk bisa mengikuti program ini.`,
        closeButton: "Tutup",
    }

    const copyEn = {
        header: "Class Prerequisites",
        item_1: `You should have a computer with at least an Intel Core i3 CPU (or equivalent) and 4 GB of RAM 
                to ensure a smooth learning experience.`,
        item_2: `You should allocate 1 hour for each session, along with additional time for independent practice. 
                Practice questions will be provided during the learning process.`,  
        item_3: `You need to pay the class fee to participate in this program.`,
        closeButton: "Close",
    }
  return (
    <div className="modal modal-1">
        <article className="modal-container">
            <header className="modal-container-header">
                <h4 className="modal-container-title">
                    {lang == "id" ? copy.header : copyEn.header}
                </h4>
                
            </header>
            <section className="modal-container-body rtf">
                <p>
                    <i className='bi bi-1-square-fill'></i>&nbsp;
                    {lang == "id" ? copy.item_1 : copyEn.item_1} <br />

                    <i className='bi bi-2-square-fill'></i>&nbsp;
                    {lang == "id" ? copy.item_2 : copyEn.item_2} <br />

                    <i className='bi bi-3-square-fill'></i>&nbsp;
                    {lang == "id" ? copy.item_3 : copyEn.item_3}

                    
                </p>
            </section>
            <footer className="modal-container-footer">
                <button className="button is-primary button-close-1">{lang == "id" ? copy.closeButton : copyEn.closeButton}</button>
            </footer>
        </article>
    </div>
    
  );
}

export default Modal1;
