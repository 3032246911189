import React, {useEffect} from 'react';
import './Navmenu.css';
import {getLanguage} from "../../util/lang";

function Navmenu() {
  useEffect(() => {
    const script = document.createElement('script');
    script.defer = true;
    script.src = './assets/js/header/navmenu/navmenu.js';
    document.body.appendChild(script);   
    
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const lang = getLanguage();

  const copy = {
    home: "Beranda",
    legality: "Legalitas",
    aboutMentor: "Tentang Mentor",
    classInfo: "Informasi Kelas",
    registerForm: "Formulir Pendaftaran",
  }

  const copyEn = {
    home: "Home",
    legality: "Legality",
    aboutMentor: "About The Mentor",
    classInfo: "Class Information",
    registerForm: "Registration Form",
  }

  return (
    <nav id="navmenu" className="navmenu">
        <ul>
            <li><a href="#beranda" className="active">{lang=="id" ? copy.home : copyEn.home}</a></li>
            <li><a href="#legalitas">{lang=="id" ? copy.legality : copyEn.legality}</a></li>
            <li><a href="#tentang-saya">{lang=="id" ? copy.aboutMentor : copyEn.aboutMentor}</a></li>
            <li><a href="#info-kursus">{lang=="id" ? copy.classInfo : copyEn.classInfo}</a></li>
            <li><a href="#form-daftar">{lang=="id" ? copy.registerForm : copyEn.registerForm}</a></li>
            {/* <li><a href="#faq-2">FAQ</a></li> */}
        </ul>
        <i className="mobile-nav-toggle d-xl-none bi bi-list"></i>
    </nav>
  );
}

export default Navmenu;
