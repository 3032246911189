import React, { useEffect } from 'react';
import './Sitename.css';

function Sitename() {
  useEffect(() => {
    const script = document.createElement('script');
    script.defer = true;
    script.src = './assets/js/header/sitename/sitename.js';
    document.body.appendChild(script);   
    
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <a href="index.html" className="logo d-flex align-items-center me-auto">
        <h1 className="sitename">KELASKODING.ID</h1>
    </a>
  );
}

export default Sitename;
