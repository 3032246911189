import React, {useEffect} from 'react';
import './InfoKursus.css';
import Modal from './modal/Modal';
import {getLanguage} from "../../util/lang";

function InfoKursus() {
    useEffect(() => {
        const script = document.createElement('script');
        script.defer = true;
        script.src = './assets/js/main/info_kursus/info_kursus.js';
        document.body.appendChild(script);   
        
        return () => {
            document.body.removeChild(script);
        };
    }, []);

    const lang = getLanguage();

    const copy = {
        header: "Informasi Kelas",
        box_1: "Waktu Kelas",
        box_2: "Prasyarat Kelas",
        box_3: "Proses Pendaftaran",
        box_4: "Proses Pembelajaran",
        box_5: "Lokasi Kelas",
        box_6: "Tanggal Mulai Kelas",
        box_7: "Harga Kelas",
        box_8: "Benefit", 
        box_9: "Disclaimer",
    };

    const copyEn = {
        header: "Class Information",
        box_1: "Class Schedule",
        box_2: "Class Prerequisites",
        box_3: "Registration Process",
        box_4: "Learning Process",
        box_5: "Class Location",
        box_6: "Class Start Date",
        box_7: "Class Price",
        box_8: "Benefits", 
        box_9: "Disclaimer",
    };

  return (
    <section id="info-kursus" className="info-kursus section">
        <div className="container section-title" data-aos="fade-up">
            <h2>{lang == "id" ? copy.header : copyEn.header}</h2>
        </div>
        <div className="container">
            <div className="row gy-4 mb-4">
                <div className="col-lg-2 col-md-4 col-6 d-flex" data-aos="fade-up" data-aos-delay="100">
                    <div className="info-item position-relative">
                        <div className="icon"><i className="bi bi-clock icon"></i></div>
                        <h4><a href="#info-kursus" className="stretched-link">{lang == "id" ? copy.box_1 : copyEn.box_1}</a></h4>
                    </div>
                </div>

                {/* <div className="col-lg-2 col-md-4 col-6 d-flex" data-aos="fade-up" data-aos-delay="200">
                    <div className="info-item position-relative">
                        <div className="icon"><i className="bi bi-patch-check icon"></i></div>
                        <h4><a href="#info-kursus" className="stretched-link">{lang == "id" ? copy.box_2 : copyEn.box_2}</a></h4>
                    </div>
                </div> */}

                <div className="col-lg-2 col-md-4 col-6 d-flex" data-aos="fade-up" data-aos-delay="300">
                    <div className="info-item position-relative">
                        <div className="icon"><i className="bi bi-box-arrow-in-right icon"></i></div>
                        <h4><a href="#info-kursus" className="stretched-link">{lang == "id" ? copy.box_3 : copyEn.box_3}</a></h4>
                    </div>
                </div>

                {/* <div className="col-lg-2 col-md-4 col-6 d-flex" data-aos="fade-up" data-aos-delay="400">
                    <div className="info-item position-relative">
                        <div className="icon"><i className="bi bi-diagram-3 icon"></i></div>
                        <h4><a href="#info-kursus" className="stretched-link">{lang == "id" ? copy.box_4 : copyEn.box_4}</a></h4>
                    </div>
                </div> */}

                <div className="col-lg-2 col-md-4 col-6 d-flex" data-aos="fade-up" data-aos-delay="200">
                    <div className="info-item position-relative">
                        <div className="icon"><i className="bi bi-globe-asia-australia icon"></i></div>
                        <h4><a href="#info-kursus" className="stretched-link">{lang == "id" ? copy.box_5 : copyEn.box_5}</a></h4>
                    </div>
                </div>

                {/* <div className="col-lg-2 col-md-4 col-6 d-flex" data-aos="fade-up" data-aos-delay="300">
                    <div className="info-item position-relative">
                        <div className="icon"><i className="bi bi-calendar-week icon"></i></div>
                        <h4><a href="#info-kursus" className="stretched-link">{lang == "id" ? copy.box_6 : copyEn.box_6}</a></h4>
                    </div>
                </div> */}

                <div className="col-lg-2 col-md-4 col-6 d-flex" data-aos="fade-up" data-aos-delay="100">
                    <div className="info-item position-relative">
                        <div className="icon"><i className="bi bi-wallet icon"></i></div>
                        <h4><a href="#info-kursus" className="stretched-link">{lang == "id" ? copy.box_7 : copyEn.box_7}</a></h4>
                    </div>
                </div>

                <div className="col-lg-2 col-md-4 col-6 d-flex" data-aos="fade-up" data-aos-delay="400">
                    <div className="info-item position-relative">
                        <div className="icon"><i className="bi bi-award icon"></i></div>
                        <h4><a href="#info-kursus" className="stretched-link">{lang == "id" ? copy.box_8 : copyEn.box_8}</a></h4>
                    </div>
                </div>

                <div className="col-lg-2 col-md-4 col-6 d-flex" data-aos="fade-up" data-aos-delay="200">
                    <div className="info-item position-relative">
                        <div className="icon"><i className="bi bi-patch-exclamation icon"></i></div>
                        <h4><a href="#info-kursus" className="stretched-link">{lang == "id" ? copy.box_9 : copyEn.box_9}</a></h4>
                    </div>
                </div>
            </div>
            {/* <div className="row gy-4 mb-4"></div> */}
        </div>
        
        <Modal />
    </section>
  );
}

export default InfoKursus;
