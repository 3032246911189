import React from 'react';
import {getLanguage} from "../../../util/lang";

function Modal8() {
    const lang = getLanguage();

    const copy = {
        header: "Disclaimer",
        item_1: `Harap diperhatikan bahwa biaya pendaftaran tidak dapat dikembalikan, oleh karena itu, 
                mohon baca dan pertimbangkan dengan seksama sebelum melakukan pendaftaran.`,
        item_2: `Jika mentor berhalangan hadir karena sakit atau kepentingan mendesak, pertemuan akan dijadwalkan ulang sesuai 
                kesepakatan antara mentor dan peserta kelas.`,
        item_3: `Namun, jika peserta kelas tidak dapat hadir, pertemuan tidak dapat dijadwalkan ulang. 
                Peserta dapat mengakses rekaman video pertemuan untuk materi yang terlewat.`,
        closeButton: 'Tutup',
    }

    const copyEn = {
        header: "Disclaimer",
        item_1: `Please note that the registration fee is non-refundable. 
                Therefore, please read and consider carefully before registering.`,
        item_2: `If the mentor is unable to attend due to illness or urgent matters, the meeting will be rescheduled according 
                to an agreement between the mentor and the class participants.`,
        item_3: `However, if a class participant is unable to attend, the meeting cannot be rescheduled. 
                Participants can access the recorded video of the meeting for missed material.`,
        closeButton: 'Close',
    }
  return (
    <div className="modal modal-8">
        <article className="modal-container">
            <header className="modal-container-header">
                <h4 className="modal-container-title">
                    {lang == "id" ? copy.header : copyEn.header}
                </h4>
                
            </header>
            <section className="modal-container-body rtf">
                <p>
                    <i className='bi bi-1-square-fill'></i>&nbsp;
                    {lang == "id" ? copy.item_1 : copyEn.item_1} <br />

                    <i className='bi bi-2-square-fill'></i>&nbsp;
                    {lang == "id" ? copy.item_2 : copyEn.item_2} <br />

                    <i className='bi bi-3-square-fill'></i>&nbsp;
                    {lang == "id" ? copy.item_3 : copyEn.item_3} <br />

                    {/* <i className='bi bi-4-square-fill'></i>&nbsp;
                    Jika peserta kursus ingin menggunakan hosting dan domain berbayar untuk blog yang telah dibuat, 
                    biaya hosting dan domain tidak ditanggung oleh KELASKODING.ID. <br /> */}

                </p>
            </section>
            <footer className="modal-container-footer">
                <button className="button is-primary button-close-8">{lang == "id" ? copy.closeButton : copyEn.closeButton}</button>
            </footer>
        </article>
    </div>
    
  );
}

export default Modal8;
